import React, { Component } from "react";
import Navbar from "./Navbar";
import Reveal from "react-reveal/Reveal";
import Zoom from 'react-reveal/Zoom';
import dog1 from "./images/d53.JPG";
import dog2 from "./images/d51.JPG";
import dog3 from "./images/d52.JPG";
import dog4 from "./images/d82.JPG";
import dog5 from "./images/d83.JPG";
import Footer from "./Footer";
import "./Video.css";
import "./Landing.css";


class Landing extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      // ********* Start of landing-page ********* //
   
      <div className="landing-page">
      {/* Navbar imported component */}
        <Navbar />
        <div className="landing-page--container">
        <div className="landing-gallery">
          <div className="landing-gallery--container">
          <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active" data-interval="3000">
              
                    <img src={dog1} className="d-block w-100" alt="..."/>
                  
                </div>
                <div className="carousel-item" data-interval="3000">
                 
                  <img src={dog2} className="d-block w-100" alt="..."/>
                 
                </div>
                <div className="carousel-item" data-interval="3000">
                 
                    <img src={dog3} className="d-block w-100" alt="..."/>
                 
                </div>
                <div className="carousel-item" data-interval="3000">
                 
                    <img src={dog4} className="d-block w-100" alt="..."/>
                  
                </div>
                <div className="carousel-item" data-interval="3000">
                
                    <img src={dog5} className="d-block w-100" alt="..."/>
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="message-board">
          <div className="message-container">
            <div className="message-cols">
              <div className="message-col--left">
                <div className="message-content">
                  <Zoom>
                    <h4>Attention Crackerdog clients!</h4>
                    <hr/>
                    <h6>Our Daycare rate will be increasing for all future reservations.</h6>
                    <p>Effective 8/17/2021</p>
                    <p>New daily rates will be $30 per day for small dogs and $35 per day for large dogs</p>
                    <h6>Our Daycare package rates will be:</h6>
                    <p>$270 for 10 days - Small dog (25lbs or less)</p>
                    <p>$320 for 10 days - Large dog (25 lbs or more)</p>
                    <h6>Our nightly Boarding rates increased in May to:</h6>
                    <p>$50 per night - First dog</p>
                    <p>$40 per night - 2nd dog boarding together</p>
                  </Zoom>
                </div>
              </div>
              <div className="message-col--right">
                <div className="message-content">
           
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div className="videoPlayer">
          <div className="videoWrapper">
            <iframe title="crackerdog-values" src="https://www.youtube.com/embed/meQwo6mOCpc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
            

      

        <div className="landing-info">
          <div className="landing-info--container">
            <Zoom duration={2000}>
              <div className="location-hours-content">
                <h4 className="location-header">Location & Hours <i className="fas fa-location-arrow"></i></h4>
              </div>
            </Zoom>
            <div className="landing-cols">
              <div className="landing-col">
               
                  <ul className="landing-ul">
                  <div className="map-border">
                  <iframe width="100%" height="350" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=8907%20Bldg.%20F%20Bee%20Caves%20Road&key=AIzaSyC1km3Ew-hH00tH8ffSFf2vqI9UyR57ils" allowfullscreen></iframe>                  
                  </div>
                  <Reveal duration={3000} left>
                    <li className="landing-address">8907 Bldg. F Bee Caves Road</li>
                    </Reveal>
                    <Reveal duration={3000} right>
                    <li className="landing-address">Austin, TX 78746</li>
                    </Reveal>
                    <hr className="landing-divider"/>
                    <Reveal duration={3000} right>
                    <li>Call:</li>
                    <li><a href="tel:512-263-1114"><i className="fas fa-mobile-alt"></i></a></li>
                    </Reveal>
                    <Reveal duration={3000} left>
                    <li>Email:</li>
                    <li>
                      <a
                        alt="email-link"
                        href="mailto:reserve@crackerdogcare.com"
                      >
                        <i className="fas fa-paper-plane"></i>
                      </a>
                    </li>
                    </Reveal>
                    <Reveal duration={3000} right>
                    <li className="landing-hours">Hours of Operation</li>
                    <hr className="landing-divider"/>
                    <li>Monday-Saturday</li>
                    <li> 8am to Noon and 5pm to 8pm</li>
                    <li>Sunday</li>
                    <li>5pm to 8pm</li>
                    </Reveal>
                  </ul>
                
              </div>
   
            </div>
          </div>
        </div>
        </div>
        <Footer/>
      </div>
      // ********* End of landing-page ********* //
    );
  }
}

export default Landing;
